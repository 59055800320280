/* Styles for the table */
.inventory-table {
  border-collapse: collapse;
  /* border: none; */
  margin: auto;
  table-layout: fixed;
  background-color: var(--primary-white);
}

.row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 1rem 0px;
  gap: 1rem;
}

@media screen and (min-width: 1200px) {
  .inventory-table {
    width: 100%;
  }
}

/* Style for the inventory count display */
.inventory-count {
  margin-top: 0px;
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: 1.7px;
  font-family: "Bebas Neue";
}

/* Styles for the table headers */
.table-header {
  border-top: 1px solid var(--table-separator);
  border-bottom: 1px solid var(--table-separator);
  font-weight: normal;
  padding: 8px;
  font-family: "Lato", sans-serif;
  width: 100%;
}

/* Styles for the table cells */
.table-cell {
  /* border-bottom: 1px solid #ddd; */
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

.past-deadline-row {
  border-left: 10px solid #ff9999; /* Red border on the left side */
  color: inherit; /* Inherit text color to maintain text visibility */

  /* background-color: #ff6666; 
  color: white;  */
}

.page-container {
  margin: auto;
  width: 100%;
  height: auto;
  min-height: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  /* width: 80%; */
}

/* Style for the search bar */
.search-bar {
  margin-bottom: 20px;
  width: 100%;
}

.search-bar input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.claimed-button {
  background-color: var(--primary-blue);
  color: var(--primary-white);
  border: 2px solid var(--border-primary-blue);
  font-size: 1rem;
  font-weight: 400;
  padding: 0px 15px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sold-button {
  background-color: var(--primary-black);
  color: var(--primary-white);
  border: 2px solid var(--border-primary-black);
  font-weight: 400;
  padding: 0px 15px;
  font-size: 1rem;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.delete-button {
  background-color: var(--primary-red);
  color: var(--primary-white);
  border: 2px solid var(--border-primary-red) !important;
  font-weight: 400 !important;
  padding: 0px 15px;
  font-size: 1rem !important;
  margin-right: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}
.cancel-button {
  background-color: var(--primary-blue) !important;
  color: var(--primary-white) !important;
  border: 2px solid var(--border-primary-blue) !important;
  font-weight: 400 !important;
  padding: 12px !important;
  font-size: 1rem !important;
  margin-right: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

/* Styles for mobile screens */
@media (max-width: 768px) {
  .table-header,
  .table-cell {
    padding: 4px;
    font-size: 14px;
  }

  .search-bar {
    margin-bottom: 10px;
  }

  .search-bar input {
    padding: 6px;
  }

  .row-between {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sold-button,
  .claimed-button,
  .delete-button {
    padding: 0px 5px;
    font-size: 0.6rem !important;
  }
}

/* p {
  margin-block-start: 0px;
  margin-block-end: 0px;
} */

.inventory-button {
  display: inline-block;
  outline: 0;
  cursor: pointer;
  text-align: center;
  border: 0;
  padding: 3.5px 12px;
  min-height: 24px;
  min-width: 24px;
  color: #ffffff;
  background: #008060;
  border: 1px solid #ffffff;
  border-radius: 6px;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px,
    rgba(0, 0, 0, 0.2) 0px -1px 0px 0px inset;
}

.inventory-button:hover {
  color: #006e52;
  background: #ffffff;
  border: 1px solid #008060;
  cursor: pointer;
}

button {
  cursor: pointer;
}

/* .button:active{
  box-shadow: inset 0px 3px 7px #3c4fe0;
  transform: translateY(2px);
} */

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.row-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

/* Style for the password form container */
#password-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

/* Style for the password text */
.password-text {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Style for the "Enter Password" button */
.green-button {
  background-color: #006e52; /* Green background color */
  color: white; /* White text color */
  padding: 10px 20px; /* Add padding to the button */
  font-size: 16px; /* Button text size */
  border: none; /* Remove button border */
  cursor: pointer; /* Add a pointer cursor on hover */
  border-radius: 5px; /* Add rounded corners */
  transition: background-color 0.3s; /* Add a smooth background color transition on hover */

  /* Optional: Add a box shadow on hover for a raised effect */
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.legend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Bebas Neue";
  font-family: 1.25rem;
  font-weight: 600;
  letter-spacing: 2px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
}

.legend-item span {
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

.filter-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px;
  min-width: 300px;
  /* width: 500px; */
}

.filter-row-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 7.5px;
  min-width: 300px;
  /* width: 100dvw; */
}
.filter-button {
  margin: 0px;
  padding: 0px;
  font-size: 0.85rem !important;
  cursor: pointer;
  color: grey !important;
  background-color: white !important;
  border: 1px solid grey !important;
  border-radius: 30px !important;
  outline: none;
  transition: 1s ease-in-out;
}

/* .filter-button:hover {
  color: var(--primary-white) !important;
  border: 1px solid var(--primary-blue) !important;
  background-color: var(--secondary-blue) !important;
  border-radius: 30px !important;
  transition: 0.5s ease-in-out;
} */

.filter-button-selected {
  margin: 0px;
  padding: 0px;
  font-size: 0.85rem !important;
  cursor: pointer;
  background-color: white !important;
  outline: none;
  color: var(--primary-white) !important;
  border: 1px solid var(--primary-blue) !important;
  background-color: var(--secondary-blue) !important;
  border-radius: 30px !important;
}

.table-cell-text {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
