/* PopupComponent.css */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  margin: 10px;
  padding: 25px;
  border-radius: 3px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.popup-table {
  border: none;
  background-color: var(--primary-white);
  margin-top: 10px;
}
.popup-textfield {
  border: none;
  margin: 0px;
  padding: 0px;
  font-family: "Lato", sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
  text-overflow: ellipsis;
}

.popup-textfield:focus {
  outline: none;
}

.popup-select {
  border: 1px solid var(--table-separator);
  margin: 0px;
  padding-right: 15px;
  padding-left: 0px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  font-family: "Lato", sans-serif;
  font-size: 0.65rem;
  font-weight: 300;
  text-overflow: ellipsis;
  text-align: left;
  display: flex;
  justify-content: flex-start;
}

.category-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.close-button {
  cursor: pointer;
  font-size: 1rem;
  color: var(--primary-blue);
  position: absolute;
  top: 10px;
  right: 10px;
}

.disclaimer {
  color: var(--primary-blue);
  font-size: 1rem;
  margin-top: 10px;
  font-family: "Bebas Neue";
  letter-spacing: 1px;
  width: 75%;
  text-align: left;
  padding-left: 0px;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .disclaimer {
    width: 90%;
    font-size: 0.8rem;
  }
}

.button-submit {
  background: var(--primary-blue) !important;
  border: 2px solid var(--secondary-blue) !important;
  padding: 10px !important;
  margin: 0px !important;
  margin-top: 10px !important;
  border-radius: 0px !important;
  color: var(--primary-white) !important;
  font-size: 0.9rem !important;
  font-family: "Bebas Neue" !important;
  letter-spacing: 0.75px !important;
  font-weight: 400 !important;
  cursor: pointer;
}

.button-submit-disabled {
  background: none;
  border: none;
  padding: 10px !important;
  margin: 0px !important;
  margin-top: 10px !important;
  border-radius: 0px !important;
  color: var(--primary-black) !important;
  font-size: 0.9rem !important;
  font-family: "Bebas Neue" !important;
  letter-spacing: 0.75px !important;
  font-weight: 300 !important;
}

.popup-content th {
  font-family: "Bebas Neue";
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--primary-blue);
  text-align: left;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 0px;
  padding-bottom: 10px;
  border-top: none !important;
  border-bottom: none;
  background-color: var(--primary-white);
}

thead,
tr {
  border-top: none !important;
}
