@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap&family=Roboto");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");

:root,
#root {
  --primary-blue: #005daa;
  --border-primary-blue: #228de5;
  --dark-primary: #353535;
  --secondary-blue: #228de5;
  --primary-black: #000000;
  --border-primary-black: #606060;
  --primary-red: #ef3f3f;
  --secondary-red: #b90c0c;
  --border-primary-red: #b90c0c;
  --primary-white: #ffffff;
  --primary-grey: #f7f7f7;
  --primary-green: #55de1f;
  --table-separator: #dcdcdc;
  --error-red: #ff0000;
  height: 100dvh;
  width: 100dvw;
}

body {
  margin: 0;
  font-family:
    "Oswald" -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: var(--primary-grey);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeue-Thin.eot");
  src:
    url("./fonts/BebasNeue-Thin.eot?#iefix") format("embedded-opentype"),
    url("./fonts/BebasNeue-Thin.woff2") format("woff2"),
    url("./fonts/BebasNeue-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeueLight.eot");
  src:
    url("./fonts/BebasNeueLight.eot?#iefix") format("embedded-opentype"),
    url("./fonts/BebasNeueLight.woff2") format("woff2"),
    url("./fonts/BebasNeueLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeueRegular.eot");
  src:
    url("./fonts/BebasNeueRegular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/BebasNeueRegular.woff2") format("woff2"),
    url("./fonts/BebasNeueRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.toastButton {
  background-color: transparent !important;
  color: var(--primary-white) !important;
  border: 1px solid var(--primary-white) !important;
  padding: 4px 8px !important;
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 14px !important;
  letter-spacing: 0.5px;
  font-family: "Bebas Neue" !important;
  cursor: pointer !important;
  border-radius: 0px !important;
  margin: 0px 5px !important;
  transition: all 0.5s !important;
}

.button {
  all: unset;
  align-items: center;
  border-radius: var(--tokens-border-radius-md);
  box-sizing: border-box;
  display: inline-flex;
  gap: var(--tokens-spacing-md);
  justify-content: center;
  min-height: 40px;
  overflow: hidden;
  padding: var(--tokens-spacing-sm) var(--tokens-spacing-lg)
    var(--tokens-spacing-sm) var(--tokens-spacing-lg);
  position: relative;
}

.button .text-wrapper {
  font-family: var(--default-14px-font-family);
  font-size: var(--default-14px-font-size);
  font-style: var(--default-14px-font-style);
  font-weight: var(--default-14px-font-weight);
  letter-spacing: var(--default-14px-letter-spacing);
  line-height: var(--default-14px-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button .icon-external {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.button.filled {
  background-color: var(--courier-colors-button-default);
  border: 1px solid;
  border-color: var(--courier-colors-structure-default);
}

.button.text .text-wrapper {
  color: var(--courier-colors-text-link);
}

.button.filled .text-wrapper {
  color: var(--courier-colors-text-button);
}

.banner {
  align-items: flex-start;
  background-color: var(--primary-red);
  color: var(--primary-white);
  border-radius: 0px;
  /* box-shadow:
    0px 0px 0px #00000008,
    0px 1px 2px #00000008,
    0px 3px 3px #00000008,
    0px 7px 4px #00000005; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: auto;
  padding: 0px;
  height: 50px;
  max-height: 50px;
  width: 100%;
}

.banner-text {
  font-family: "Bebas Neue";
  letter-spacing: 1.75px;
  font-weight: 600;
  font-size: 0.85rem;
  margin-left: 5px;
  padding: 5px;
  margin-right: 175px;
}
.banner .banner-icon {
  min-width: 24px;
  margin-left: 5px;
}
.banner .close {
  position: absolute;
  right: 30px;
  /* top: 10px; */
  cursor: pointer;
  font-family: "Bebas Neue";
  font-size: 1.5rem;
}

.banner .banner-button {
  font-family: "Bebas Neue";
  padding: 4px 8px;
  font-size: 16px;
  color: var(--primary-white);
  letter-spacing: 1px;
  border: 1px solid var(--primary-white);
  border-radius: 0px;
  position: absolute;
  cursor: pointer;
  right: 60px;
  background: transparent;
}

.lastIndexPageName {
  font-family: "Bebas Neue";
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--primary-black);
  margin: 0px;
  margin-left: 5px;
  padding: 0px;
}

button {
  /* min-width: 125px;
  min-height: 50px; */
  border-radius: 0px;
  margin: 10px;
  padding: 12px 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Bebas Neue";
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.table-cell button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  min-width: unset !important;
  min-height: unset !important;
  margin-left: 10px;
  padding: unset !important;
}

.logout-button {
  border-radius: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
  display: flex !important;
  min-height: unset !important;
  /* height: 20px !important; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none !important;
  color: var(--primary-red) !important;
}

.disclaimer-circle {
  color: var(--primary-blue);
  font-size: 14px;
  font-family: "Bebas Neue";
  font-weight: 500;
  letter-spacing: 0.75px;
  text-align: left;
  margin-bottom: 5px;
}

.profile-name {
  font-family: "Bebas Neue";
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--primary-black);
  margin: 0px;
  padding: 0px;
}

.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  overflow-y: hidden !important;
  background-color: var(--primary-white);
  /* height: auto;
  width: 100%; */
}

.Header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

header {
  font-family: "Bebas Neue";
  color: white;
}

.header {
  font-size: 2rem;
  font-family: "Bebas Neue";
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  font-weight: 500;
  letter-spacing: 1.7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-field {
  font-family: "Bebas Neue";
  font-size: 1rem;
  font-weight: 300;
  color: var(--primary-blue);
  padding: 0px;
  margin-top: 10px !important;
  margin-bottom: 0px;
  border: 1px solid var(--primary-black);
  background-color: var(--primary-white);
  border-radius: 0px;
  width: 90%;
  min-height: 40px;
  height: auto;
  box-sizing: border-box;
  vertical-align: unset !important;
}

.Logo-Container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: auto;
  width: 100%;
}

.logo {
  height: auto;
  width: 3em;
  object-fit: contain;
}

.logo-text {
  height: 1.5em;
  width: auto;
}

.logo-300 {
  width: 310px;
  /* height: 50px; */
  margin-left: 7.5px;
  cursor: pointer;
}

.Navbar-menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--primary-black);
  border: none !important;
  padding: 0px;
  margin: 0px;
  max-height: 1rem;
}

.Navbar-menu {
  cursor: pointer;
  color: white;
  border: none !important;
  /* display: none; */
}

.Navbar-menu p {
  color: white;
}

.Mobile-menu {
  display: none;
}

.navButtonText {
  font-family: "Bebas Neue Light";
  font-size: 0.75rem;
  font-weight: 300;
  color: white;
}

.icon {
  cursor: pointer;
  margin-left: 2.5px;
}

@media screen and (max-width: 900px) {
  .Menu-responsive {
    display: none;
  }
  .Navbar-menu {
    display: block;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.copywrite {
  font-family: "Bebas Neue";
  font-size: 18px;
  font-weight: 400;
  color: var(--primary-black);
  text-align: center;
  padding-bottom: 1rem;
}

.App-header {
  background-color: var(--primary-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: calc(10px + 2vmin);
  width: 100%;
  max-width: 1368px;
  height: auto;
  margin: auto;
  padding: 15px 0px;
  filter: drop-shadow(var(--primary-black) 0rem 2px rgba(0, 0, 0, 0.4));
}

.small-blue-button {
  background-color: var(--primary-blue) !important;
  color: var(--primary-white) !important;
  border: 2px solid var(--border-primary-blue) !important;
  font-size: 1rem !important;
  font-family: "Bebas Neue" !important;
  display: flex !important;
  flex-direction: row !important;
  /* justify-content: space-evenly !important;
  padding: 4px 8px !important; */
}

@media screen and (max-width: 700px) {
  .banner {
    max-height: 50px;
    transition: all 0.5s;
  }

  .banner-text {
    font-size: 0.6rem;
    margin-right: 20px;
    transition: all 0.5s;
  }

  .banner .banner-icon {
    min-width: 20px;
    transition: all 0.5s;
  }

  .banner .banner-button {
    display: none;
    opacity: 0;
    transition: all 0.5s;
  }

  .banner .close {
    right: 10px;
    font-size: 0.85rem;
    transition: all 0.5s;
  }

  .App-header {
    height: 60px;
    padding: 5px 0px;
  }

  .logo-300 {
    width: 207px;
    /* height: 34px; */
  }

  .copywrite {
    font-size: 14px;
    bottom: 10px;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add this CSS to your App.css or a separate CSS file */

/* Style the tab buttons */
.tab-button {
  background-color: transparent; /* Default background for non-active tabs */
  border: none;
  color: #333; /* Default text color */
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
}

/* Style the active tab */
.tab-button.active {
  background-color: lightblue; /* Light blue background for active tab */
  color: #fff; /* White text color for active tab */
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  /* overflow: auto !important; */
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 250px;
  background-color: var(--primary-grey);
}

.container::-webkit-scrollbar {
  display: none;
}

/* Style for the container div */
.back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  transition: opacity 0.5s; /* Add a smooth opacity transition */
}

/* Style for the visible state */
.visible {
  opacity: 1; /* Make the button visible */
}

/* Style for the hidden state */
/* You can customize this as needed */
.visible.hidden {
  opacity: 0;
  pointer-events: none; /* Disable pointer events to make the button unclickable when hidden */
}

/* BackToTopButton.css */
.fab-button {
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
}

.fab-button:hover {
  background-color: #0056b3; /* Hover background color */
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  overflow-y: auto;
  border: 1px solid var(--table-separator);
}

th {
  padding: 8px;
  text-align: left;
}

td {
  padding: 12px 6px;
  text-align: left;
}

thead,
th {
  background-color: #f2f2f2;
  text-align: left;
  top: -1px;
  position: sticky;
  z-index: 1000 !important;
  border-top: 1px solid var(--table-separator);
  border-bottom: 1px solid var(--table-separator);
  /* width: 100%; */
}

tr {
  border-bottom: 1px solid var(--table-separator);
  border-top: 1px solid var(--table-separator);
}

.table-container {
  /* overflow: auto !important; */
  height: auto;
  padding: 5px;
  /* margin-top: 1rem; */
}

.detailed-text {
  margin: 0px;
  padding: 10px;
  font-size: 0.85rem;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  border-bottom: 1px solid vaR(--table-separator);
  width: 99%;
}

.detailed-text-nopadding {
  margin: 0px;
  padding: 0px;
  font-size: 0.85rem;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

.column-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.row-apart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.row-back {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  max-width: 1386px;
  width: 100%;
}

.back-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--primary-blue);
  cursor: pointer;
  transition: all 0.5s;
}

@media screen and (max-width: 800px) {
  .back-icon {
    width: 1rem;
    height: 1rem;
  }

  .row-back {
    width: 95%;
  }
}

.ptr-override {
  height: auto !important;
  overflow: unset !important;
  min-height: auto !important;
}
