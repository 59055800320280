.circle-guide {
  border: 2px solid #fff; /* White border */
  border-radius: 50%; /* Makes the shape a circle */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the circle */
  pointer-events: none; /* Allows the camera feed to be clicked through the circle */
  width: 75%;
  height: 75%;
  box-sizing: border-box;
}

.camera-component-container {
  flex: 0 0 85%; /* 75% width, no grow, no shrink */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  width: auto;
  height: auto;
  aspect-ratio: 1 / 1;
}

.webcam {
  aspect-ratio: 1 / 1;
  box-sizing: border-box;
  width: auto;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  border: 2px solid var(--primary-red);
  position: relative;
}

.button-blue {
  color: var(--primary-white);
  background-color: var(--primary-blue);
  border: 1px solid var(--border-primary-blue);
  font-size: 0.85rem !important;
}

.button-blue:hover,
.button-blue:focus,
.button-blue:active {
  color: var(--primary-blue);
  background: #ffffff;
  border: 1px solid var(--primary-blue);
  cursor: pointer;
}

.button-switch {
  width: 175px; /* Large square size */
  height: 175px;
  border-radius: 10px; /* Rounded corners */
  margin: 10px;
  padding: 10px !important;
  font-size: 16px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  background: grey;
  border: 1px solid #ffffff;
}

.button-switch:hover,
.button-switch:focus,
.button-switch:active {
  color: #006e52;
  background: #ffffff;
  border: 1px solid grey;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  /* Adjust for mobile */
  .button-blue {
    width: 125px;
    height: 125px;
    font-size: 0.85rem !important;
  }

  .button-switch {
    width: 125px;
    height: 125px;
    font-size: 0.85rem !important;
  }
  .camera-component-container {
    /* Adjust the size or padding based on your design */
    max-width: 70%;
    padding: 10px;
  }
}

@media screen and (min-width: 601px) {
  /* Adjust for larger screens */
  .button-blue {
    width: 150px;
    height: 150px;
    font-size: 0.85rem !important;
  }

  .button-switch {
    width: 150px;
    height: 150px;
    font-size: 0.85rem !important;
  }

  .camera-component-container {
    /* Adjust the size or padding based on your design */
    max-width: 70%;
    padding: 10px;
  }
}
/* 
/* Example media query for larger screens */
@media (min-width: 768px) {
  .camera-component-container {
    /* Adjust the size or padding based on your design */
    max-width: 65%;
    padding: 10px;
  }
}

/* Additional media queries can be added for different screen sizes */
@media (min-width: 1024px) {
  .camera-component-container {
    max-width: 50%;
    padding: 20px;
  }
}
