/* EnterLostDisc.css */

.lost-disc-container {
  /* margin: auto; */
  height: auto;
  width: 95%;
  max-width: 1386px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

input {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 0px;
  font-size: 16px;
}
.form-buttons-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.reset-format {
  background-color: #f0efef;
  color: #878787;
  border: 1px solid;
  border-radius: 0px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 15px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 125px;
}

.reset-format:hover {
  background-color: #c8c8c8;
  transition: 0.3s;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 0px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 15px;
  text-transform: uppercase;
  height: 40px;
  width: 125px;
}

.submit-button:hover {
  background-color: #0056b3;
  transition: 0.3s;
}

.success-message {
  margin-top: 20px;
  padding: 20px;
  border-radius: 0px;
  background-color: #d4edda;
  color: #155724;
}

.error-message {
  margin-top: 20px;
  padding: 20px;
  border-radius: 0px;
  background-color: lightgray;
  color: red;
  font-weight: bold;
}

.button-done {
  color: var(--primary-black);
  background: none;
  border: 1px solid var(--primary-black);
  flex-direction: row;
  font-size: 0.85rem !important;
}

.button-options-red {
  margin: 10px;
  padding: 5px 15px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: var(--primary-red);
  border: 2px solid var(--secondary-red);
  flex-direction: row;
  max-width: 175px;
}

.button-options-red:hover,
.button-options-red:focus,
.button-options-red:active,
.button-options-black:hover,
.button-options-black:focus,
.button-options-black:active {
  color: var(--primary-white);
  background-color: var(--primary-blue);
  border: 2px solid var(--secondary-blue);
  cursor: pointer;
}

.button-options-black {
  margin: 10px;
  padding: 5px 15px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: var(--primary-black);
  border: 2px solid var(--border-primary-black);
  flex-direction: row;
  max-width: 175px;
}

@media screen and (max-width: 600px) {
  /* Adjust for mobile */
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-bottom: 1.2em;
    text-align: center;
    /* margin: auto; */
  }

  .button-options-black,
  .button-options-red {
    width: 100%;
    height: 40px;
    font-size: 0.8rem !important;
    border-radius: 0px;
  }
}

@media screen and (max-width: 375px) {
  .button-options-black,
  .button-options-red {
    height: 40px;
    padding: 5px;
    font-size: 0.65rem !important;
    border-radius: 0px;
  }
}

@media screen and (min-width: 601px) {
  /* Adjust for larger screens */
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin: auto;
  }
}

.EnterLostDisc {
  font-size: 60px;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  margin: 0px;
}

.button-icon-camera,
.button-icon-pencil {
  margin: 5px;
  padding-right: 5px;
  font-size: 7px;
}

.button-icon-pencil {
  font-size: 5px;
}

.button-icon {
  font-size: 40px; /* Larger icon size */
  width: 40% !important;
  height: 30% !important;
  margin-bottom: 5px;
  padding: 0;
}

.button-text {
  text-align: center; /* Center the text */
}

/* Add this to your CSS file */
.image-container {
  width: 7rem;
  height: 7rem;
  /* border: 2px dashed #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background-color: var(--primary-black);
  color: var(--primary-grey);
}

/* Style for the camera icon */
.image-container .camera-icon {
  font-size: 2rem;
  color: var(--primary-grey);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style for the image itself */
.image-container img {
  max-width: 100%;
  max-height: auto;
  object-fit: cover;
}

.image-placeholder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  gap: 0.25rem;
  flex: 1; /* Takes the remaining 25% width */
}

.image-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100%;
  height: 0;
  padding-bottom: 100%; 
  background-color: #f0f0f0;
  border: 2px dashed #ccc;  */
  cursor: pointer;
  position: relative;
}

.image-placeholder-label {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary-black);
  margin: 0px;
  padding: 0px;
  margin-bottom: 1.5rem;
}

.camera-icon {
  font-size: 2rem;
  color: #777; /* Color of the camera icon */
  margin-bottom: 8px; /* Spacing between camera icon and text */
}

.use-camera-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
}

/* Additional media queries can be added for different screen sizes */
@media (min-width: 1024px) {
  .image-container .camera-icon {
    font-size: 2.5rem;
  }
}

/* Additional media queries can be added for different screen sizes */
@media (max-width: 800px) {
  .image-container .camera-icon {
    font-size: 2rem;
  }

  .image-container {
    width: 6rem;
    height: 6rem;
  }

  .EnterLostDisc {
    font-size: 35px;
  }
}

/* Additional media queries can be added for different screen sizes */
@media (max-width: 700px) {
  .image-container .camera-icon {
    font-size: 1.75rem;
  }

  .image-container {
    width: 5rem;
    height: 5rem;
  }
}

/* Additional media queries can be added for different screen sizes */
@media (max-width: 600px) {
  .image-container .camera-icon {
    font-size: 1.5rem;
  }

  .image-container {
    width: 4rem;
    height: 4rem;
  }
}
